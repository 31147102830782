<template>
  <div class="container">404 Page not found</div>
</template>

<script>
export default {
  name: 'PageNotFound',
  data() {
    return {}
  },
}
</script>

<style lang="scss">
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
